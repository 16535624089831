import * as React from "react";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import homeIcon from "../../../assets/menu/home.svg";
import saleIcon from "../../../assets/menu/sales.svg";
import areaManagerIcon from "../../../assets/menu/area_manager.svg";
import opsIcon from "../../../assets/menu/operations.svg";
import marketingIcon from "../../../assets/menu/marketing.svg";
import aggregatorIcon from "../../../assets/menu/aggregator.svg";
import accountsIcon from "../../../assets/menu/accounts.svg";
import analyticsIcon from "../../../assets/menu/analytics.svg";
import crmIcon from "../../../assets/menu/crm.svg";
import extrasIcon from "../../../assets/menu/extras.svg";
import ticketsIcon from "../../../assets/menu/tickets.svg";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { push } from "connected-react-router";

const drawerWidth = 100;

const MenuDrawer = () => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.employeeAuth.loginData?.role);
  const location = useLocation();

  const [selectedMenu, setSelectedMenu] = React.useState({});
  const [tab, setTab] = React.useState(0);
  const [subTab, setSubTab] = React.useState(false);

  React.useEffect(() => {
    const currentMenuOption = getCurrentMenuOption();
    setSelectedMenu(currentMenuOption);
    const currentTabIndex = getCurrentMenuTab(currentMenuOption);
    setTab(currentTabIndex);
    if (currentMenuOption?.elements[currentTabIndex]?.items?.length > 0) {
      const currentSubTabIndex = getCurrentMenuSubTab(
        currentMenuOption,
        currentTabIndex
      );
      setSubTab(currentSubTabIndex);
      currentMenuOption?.elements[currentTabIndex]?.items[
        currentSubTabIndex
      ]?.handleMenu();
    } else {
      currentMenuOption?.elements[currentTabIndex].handleMenu();
    }
  }, [location.pathname]);

  const menuOptions = [
    {
      title: "Home",
      visible: true,
      icon: homeIcon,
      active: location.pathname === "/employee/home",
      elements: [
        {
          label: "Home",
          active: location.pathname === "/employee/home",
          handleMenu: () => {
            dispatch(push("/employee/home"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "Employee",
      visible: roles?.includes("hr") || roles?.includes("admin"),
      icon: opsIcon,
      active:
        location.pathname === "/employee/employee-list" ||
        location.pathname === "/employee/employee-onboard",
      elements: [
        {
          label: "Employees",
          active: location.pathname === "/employee/employee-list",
          handleMenu: () => {
            dispatch(push("/employee/employee-list"));
          },
          visible: roles?.includes("hr") || roles?.includes("admin"),
        },
        {
          label: "Employee onboard",
          active: location.pathname === "/employee/employee-onboard",
          handleMenu: () => {
            dispatch(push("/employee/employee-onboard"));
          },
          visible: roles?.includes("hr"),
        },
      ],
    },
    // {
    //   title: "Admin",
    //   visible: roles?.includes("temporary"),
    //   icon: opsIcon,
    //   active:
    //     location.pathname === "/employee/temporary/admin/sales" ||
    //     location.pathname === "/employee/temporary/admin/mom",
    //   elements: [
    //     {
    //       label: "Sales",
    //       active: location.pathname === "/employee/temporary/admin/sales",
    //       handleMenu: () => {
    //         dispatch(push("/employee/temporary/admin/sales"));
    //       },
    //       visible: roles?.includes("temporary"),
    //     },
    //     {
    //       label: "MoM",
    //       active: location.pathname === "/employee/temporary/admin/mom",
    //       handleMenu: () => {
    //         dispatch(push("/employee/temporary/admin/mom"));
    //       },
    //       visible: roles?.includes("temporary"),
    //     },
    //   ],
    // },

    {
      title: "Franchise management",
      visible:
        roles?.includes("aggregator") ||
        roles?.includes("operations head") ||
        roles?.includes("admin") ||
        roles?.includes("accounts") ||
        roles?.includes("sales head") ||
        roles?.includes("marketing") ||
        roles?.includes("store manager") ||
        roles?.includes("area manager"),
      icon: saleIcon,
      active:
        location.pathname === "/employee/initiate-partner-on-boarding" ||
        location.pathname === "/employee/partner-status" ||
        location.pathname === "/employee/partner-approval-operations-head" ||
        location.pathname === "/employee/partner-list" ||
        location.pathname === "/employee/aggregator-onboarding" ||
        location.pathname === "/employee/fssai-expiry" ||
        location.pathname === "/employee/aggregator-discount" ||
        location.pathname === "/employee/manage-mails" ||
        location.pathname === "/employee/launch-tracker" ||
        // location.pathname === "/employee/rista-mapping" ||
        location.pathname === "/employee/aggregation-mail-details" ||
        location.pathname === "/employee/partner/forecast" ||
        location.pathname === "/employee/partner/learning-resources" ||
        location.pathname === "/employee/zs-daily" ||
        location.pathname === "/employee/new-brand-addition" ||
        location.pathname === "/employee/wp-messages" ||
        location.pathname === "/employee/cpc" ||
        location.pathname === "/employee/cpc-requests" ||
        location.pathname === "/employee/pre-launch-summary" ||
        location.pathname === "/employee/academy" ||
        location.pathname === "/employee/po-details" ||
        location.pathname === "/employee/tickets-overview" ||
        location.pathname === "/employee/delayed-reports" ||
        location.pathname === "/employee/tickets-categories" ||
        location.pathname === "/employee/dashboard-access" ||
        location.pathname === "/employee/faq-bank" ||
        location.pathname === "/employee/news" ||
        location.pathname === "/employee/property" ||
        location.pathname === "/employee/department" ||
        location.pathname === "/employee/region" ||
        location.pathname === "/employee/brands" ||
        location.pathname === "/employee/document-uploader" ||
        location.pathname === "/employee/banners" ||
        location.pathname === "/employee/cuisine" ||
        location.pathname === "/employee/partner-bucket" ||
        location.pathname === "/employee/logs" ||
        location.pathname === "/employee/flags" ||
        location.pathname === "/employee/scheduler-logs" ||
        location.pathname === "/employee/upload-status" ||
        location.pathname === "/employee/temporary/admin/sales" ||
        location.pathname === "/employee/temporary/admin/mom" ||
        location.pathname === "/employee/marketing-leads-upload" ||
        location.pathname === "/employee/lead-gens" ||
        location.pathname === "/employee/blogger-order-charges" ||
        location.pathname === "/employee/marketing-leads" ||
        location.pathname === "/employee/marketing-leads-info" ||
        location.pathname === "/employee/marketing-stories" ||
        location.pathname === "/employee/blogger-order" ||
        location.pathname === "/employee/marketing-lead-report",

      elements: [
        {
          label: "Admin",
          active:
            location.pathname === "/employee/temporary/admin/sales" ||
            location.pathname === "/employee/temporary/admin/mom",
          visible: roles?.includes("temporary"),
          items: [
            {
              label: "Sales",
              active: location.pathname === "/employee/temporary/admin/sales",
              handleMenu: () => {
                dispatch(push("/employee/temporary/admin/sales"));
              },
              visible: roles?.includes("temporary"),
            },
            {
              label: "MoM",
              active: location.pathname === "/employee/temporary/admin/mom",
              handleMenu: () => {
                dispatch(push("/employee/temporary/admin/mom"));
              },
              visible: roles?.includes("temporary"),
            },
          ],
        },
        {
          label: "Marketing",
          active:
            location.pathname === "/employee/marketing-leads-upload" ||
            location.pathname === "/employee/lead-gens" ||
            location.pathname === "/employee/blogger-order-charges" ||
            location.pathname === "/employee/marketing-leads" ||
            location.pathname === "/employee/marketing-leads-info" ||
            location.pathname === "/employee/marketing-stories" ||
            location.pathname === "/employee/blogger-order" ||
            location.pathname === "/employee/marketing-lead-report",
          visible:
            roles?.includes("marketing") ||
            roles?.includes("sales head") ||
            roles?.includes("sales") ||
            roles?.includes("area manager") ||
            roles?.includes("accounts"),
          items: [
            {
              label: "Upload marketing leads",
              active: location.pathname === "/employee/marketing-leads-upload",
              handleMenu: () => {
                dispatch(push("/employee/marketing-leads-upload"));
              },
              visible: roles?.includes("marketing"),
            },
            {
              label: "Marketing leads",
              active: location.pathname === "/employee/marketing-leads",
              handleMenu: () => {
                dispatch(push("/employee/marketing-leads"));
              },
              visible: roles?.includes("sales head"),
            },
            {
              label: "Marketing leads info",
              active: location.pathname === "/employee/marketing-leads-info",
              handleMenu: () => {
                dispatch(push("/employee/marketing-leads-info"));
              },
              visible: roles?.includes("sales"),
            },
            {
              label: "Marketing Stories",
              active: location.pathname === "/employee/marketing-stories",
              handleMenu: () => {
                dispatch(push("/employee/marketing-stories"));
              },
              visible: roles?.includes("marketing"),
            },
            {
              label: "Blogger Order",
              active: location.pathname === "/employee/blogger-order",
              handleMenu: () => {
                dispatch(push("/employee/blogger-order"));
              },
              visible:
                roles?.includes("marketing") ||
                roles?.includes("area manager") ||
                roles?.includes("accounts"),
            },
            {
              label: "Marketing leads report",
              active: location.pathname === "/employee/marketing-lead-report",
              handleMenu: () => {
                dispatch(push("/employee/marketing-lead-report"));
              },
              visible:
                roles?.includes("marketing") ||
                roles?.includes("sales") ||
                roles?.includes("sales head"),
            },
            {
              label: "Blogger Order Charges",
              active: location.pathname === "/employee/blogger-order-charges",
              handleMenu: () => {
                dispatch(push("/employee/blogger-order-charges"));
              },
              visible:
                roles?.includes("marketing") ||
                roles?.includes("sales") ||
                roles?.includes("sales head"),
            },
            {
              label: "Lead Gen",
              active: location.pathname === "/employee/lead-gens",
              handleMenu: () => {
                dispatch(push("/employee/lead-gens"));
              },
              visible:
                roles?.includes("marketing") ||
                roles?.includes("sales") ||
                roles?.includes("sales head"),
            },
          ],
        },
        {
          label: "Sales",

          active:
            location.pathname === "/employee/initiate-partner-on-boarding" ||
            location.pathname === "/employee/partner-status" ||
            location.pathname === "/employee/partner-list",
          visible:
            roles?.includes("sales") ||
            roles?.includes("area manager") ||
            roles?.includes("sales head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("crm"),
          items: [
            {
              label: "Partner onboarding",
              active:
                location.pathname === "/employee/initiate-partner-on-boarding",
              handleMenu: () => {
                dispatch(push("/employee/initiate-partner-on-boarding"));
              },
            },
            {
              label: "Partner Status",
              active: location.pathname === "/employee/partner-status",
              handleMenu: () => {
                dispatch(push("/employee/partner-status"));
              },
            },
            {
              label: "Partner List",
              active: location.pathname === "/employee/partner-list",
              handleMenu: () => {
                dispatch(push("/employee/partner-list"));
              },
            },
          ],
        },

        {
          label: "Operations",
          active:
            location.pathname === "/employee/partner-approval-operations-head",
          visible: roles?.includes("operations head"),
          items: [
            {
              label: "Partner approval",
              active:
                location.pathname ===
                "/employee/partner-approval-operations-head",
              handleMenu: () => {
                dispatch(push("/employee/partner-approval-operations-head"));
              },
            },
          ],
        },

        {
          label: "Aggregator",
          active:
            location.pathname === "/employee/aggregator-onboarding" ||
            location.pathname === "/employee/fssai-expiry" ||
            location.pathname === "/employee/aggregator-discount" ||
            location.pathname === "/employee/manage-mails" ||
            location.pathname === "/employee/launch-tracker" ||
            // location.pathname === "/employee/rista-mapping" ||
            location.pathname === "/employee/aggregation-mail-details" ||
            location.pathname === "/employee/partner/forecast" ||
            location.pathname === "/employee/partner/learning-resources" ||
            location.pathname === "/employee/zs-daily" ||
            location.pathname === "/employee/new-brand-addition" ||
            location.pathname === "/employee/wp-messages" ||
            location.pathname === "/employee/cpc" ||
            location.pathname === "/employee/cpc-requests" ||
            location.pathname === "/employee/pre-launch-summary" ||
            location.pathname === "/employee/po-details",
          visible:
            roles?.includes("admin") ||
            roles?.includes("aggregator") ||
            roles?.includes("area manager"),
          items: [
            {
              label: "Partner Onboarding/Training",
              active: location.pathname === "/employee/aggregator-onboarding",
              handleMenu: () => {
                dispatch(push("/employee/aggregator-onboarding"));
              },
            },
            {
              label: "FSSAI expiry",
              active: location.pathname === "/employee/fssai-expiry",
              handleMenu: () => {
                dispatch(push("/employee/fssai-expiry"));
              },
            },

            {
              label: "Partner Discounts",
              active: location.pathname === "/employee/aggregator-discount",
              handleMenu: () => {
                dispatch(push("/employee/aggregator-discount"));
              },
            },
            {
              label: "Manage Mails",
              active: location.pathname === "/employee/manage-mails",
              handleMenu: () => {
                dispatch(push("/employee/manage-mails"));
              },
            },
            {
              label: "Launch Tracker",
              active: location.pathname === "/employee/launch-tracker",
              handleMenu: () => {
                dispatch(push("/employee/launch-tracker"));
              },
            },
            {
              label: "Pre-Launch Summary",
              active: location.pathname === "/employee/pre-launch-summary",
              handleMenu: () => {
                dispatch(push("/employee/pre-launch-summary"));
              },
            },
            {
              label: "Aggregation mail details",
              active:
                location.pathname === "/employee/aggregation-mail-details",
              handleMenu: () => {
                dispatch(push("/employee/aggregation-mail-details"));
              },
            },
            {
              label: "PO Details",
              active: location.pathname === "/employee/po-details",
              handleMenu: () => {
                dispatch(push("/employee/po-details"));
              },
            },
            {
              label: "CPC Requests",
              active: location.pathname === "/employee/cpc-requests",
              handleMenu: () => {
                dispatch(push("/employee/cpc-requests"));
              },
              visible: roles?.includes("admin"),
            },
            {
              label: "Partner Sales Forecast",
              active: location.pathname === "/employee/partner/forecast",
              handleMenu: () => {
                dispatch(push("/employee/partner/forecast"));
              },
              visible:
                roles?.includes("area manager") ||
                roles?.includes("aggregator"),
            },
            {
              label: "Learning Resources",
              active:
                location.pathname === "/employee/partner/learning-resources",
              handleMenu: () => {
                dispatch(push("/employee/partner/learning-resources"));
              },
              visible:
                roles?.includes("area manager") ||
                roles?.includes("aggregator"),
            },
            {
              label: "ZS Daily",
              active: location.pathname === "/employee/zs-daily",
              handleMenu: () => {
                dispatch(push("/employee/zs-daily"));
              },
              visible: roles?.includes("aggregator"),
            },
            {
              label: "CPC",
              active: location.pathname === "/employee/cpc",
              handleMenu: () => {
                dispatch(push("/employee/cpc"));
              },
              visible: roles?.includes("aggregator"),
            },
            {
              label: "New Brand Addition",
              active: location.pathname === "/employee/new-brand-addition",
              handleMenu: () => {
                dispatch(push("/employee/new-brand-addition"));
              },
              visible: roles?.includes("aggregator"),
            },
            {
              label: "Whatsapp Messages",
              active: location.pathname === "/employee/wp-messages",
              handleMenu: () => {
                dispatch(push("/employee/wp-messages"));
              },
              visible: roles?.includes("aggregator"),
            },
          ],
        },

        {
          label: "Academy",
          active: location.pathname === "/employee/academy",
          visible: true,
          items: [
            {
              label: "Traning Documents",
              active: location.pathname === "/employee/academy",
              handleMenu: () => {
                dispatch(push(`/employee/academy`));
              },
              visible:
                roles?.includes("aggregator") ||
                roles?.includes("area manager") ||
                roles?.includes("store manager"),
            },
          ],
        },

        {
          label: "CRM",
          active:
            location.pathname === "/employee/tickets-overview" ||
            location.pathname === "/employee/delayed-reports" ||
            location.pathname === "/employee/tickets-categories" ||
            location.pathname === "/employee/dashboard-access" ||
            location.pathname === "/employee/faq-bank" ||
            location.pathname === "/employee/news",
          visible: roles?.includes("crm"),
          items: [
            {
              label: "Tickets overview",
              active: location.pathname === "/employee/tickets-overview",
              handleMenu: () => {
                dispatch(push("/employee/tickets-overview"));
              },
              visible: roles?.includes("crm"),
            },
            {
              label: "Delayed Reports",
              active: location.pathname === "/employee/delayed-reports",
              handleMenu: () => {
                dispatch(push("/employee/delayed-reports"));
              },
              visible: roles?.includes("crm"),
            },
            {
              label: "Manage News",
              active: location.pathname === "/employee/news",
              handleMenu: () => {
                dispatch(push("/employee/news"));
              },
              visible: roles?.includes("crm"),
            },
            {
              label: "FAQ Bank(WIP)",
              active: location.pathname === "/employee/faq-bank",
              handleMenu: () => {
                dispatch(push("/employee/faq-bank"));
              },
              visible: roles?.includes("crm"),
            },
            {
              label: "Ticket Category",
              active: location.pathname === "/employee/tickets-categories",
              handleMenu: () => {
                dispatch(push("/employee/tickets-categories"));
              },
              visible: roles?.includes("crm"),
            },
            {
              label: "Dashboard Access",
              active: location.pathname === "/employee/dashboard-access",
              handleMenu: () => {
                dispatch(push("/employee/dashboard-access"));
              },
              visible: roles?.includes("crm"),
            },
          ],
        },

        {
          label: "Extras",
          active:
            location.pathname === "/employee/property" ||
            location.pathname === "/employee/department" ||
            location.pathname === "/employee/region" ||
            location.pathname === "/employee/brands" ||
            location.pathname === "/employee/document-uploader" ||
            location.pathname === "/employee/banners" ||
            location.pathname === "/employee/cuisine" ||
            location.pathname === "/employee/partner-bucket" ||
            location.pathname === "/employee/logs" ||
            location.pathname === "/employee/flags" ||
            location.pathname === "/employee/scheduler-logs" ||
            location.pathname === "/employee/upload-status",
          visible: true,
          items: [
            {
              label: "Property",
              visible: roles?.includes("admin"),
              active: location.pathname === "/employee/property",
              handleMenu: () => {
                dispatch(push("/employee/property"));
              },
            },
            {
              label: "Department",
              visible: roles?.includes("admin"),
              active: location.pathname === "/employee/department",
              handleMenu: () => {
                dispatch(push("/employee/department"));
              },
            },
            {
              label: "Region",
              visible:
                roles?.includes("admin") || roles?.includes("aggregator"),
              active: location.pathname === "/employee/region",
              handleMenu: () => {
                dispatch(push("/employee/region"));
              },
            },
            {
              label: "Brands",
              visible:
                roles?.includes("admin") || roles?.includes("aggregator"),
              active: location.pathname === "/employee/brands",
              handleMenu: () => {
                dispatch(push("/employee/brands"));
              },
            },
            {
              label: "Cuisine",
              visible:
                roles?.includes("admin") || roles?.includes("aggregator"),
              active: location.pathname === "/employee/cuisine",
              handleMenu: () => {
                dispatch(push("/employee/cuisine"));
              },
            },
            {
              label: "Partner Buckets",
              visible:
                roles?.includes("admin") || roles?.includes("aggregator"),
              active: location.pathname === "/employee/partner-bucket",
              handleMenu: () => {
                dispatch(push("/employee/partner-bucket"));
              },
            },
            {
              label: "Document Uploader",
              visible:
                roles?.includes("admin") || roles?.includes("aggregator"),
              active: location.pathname === "/employee/document-uploader",
              handleMenu: () => {
                dispatch(push("/employee/document-uploader"));
              },
            },
            {
              label: "Banners",
              visible: roles?.includes("admin"),
              active: location.pathname === "/employee/banners",
              handleMenu: () => {
                dispatch(push("/employee/banners"));
              },
            },
            {
              label: "Upload Status",
              visible: true,
              active: location.pathname === "/employee/upload-status",
              handleMenu: () => {
                dispatch(push("/employee/upload-status"));
              },
            },
            {
              label: "Logs",
              visible: true,
              active: location.pathname === "/employee/logs",
              handleMenu: () => {
                dispatch(push("/employee/logs"));
              },
            },
            {
              label: "Flags",
              visible: roles?.includes("admin"),
              active: location.pathname === "/employee/flags",
              handleMenu: () => {
                dispatch(push("/employee/flags"));
              },
            },
            {
              label: "Scheduler Logs",
              visible: roles?.includes("admin"),
              active: location.pathname === "/employee/scheduler-logs",
              handleMenu: () => {
                dispatch(push("/employee/scheduler-logs"));
              },
            },
          ],
        },
      ],
    },

    // {
    //   title: "Sales",
    //   visible:
    //     roles?.includes("sales") ||
    //     roles?.includes("area manager") ||
    //     roles?.includes("sales head") ||
    //     roles?.includes("admin") ||
    //     roles?.includes("accounts") ||
    //     roles?.includes("crm"),
    //   icon: saleIcon,
    //   active:
    //     location.pathname === "/employee/initiate-partner-on-boarding" ||
    //     location.pathname === "/employee/partner-status" ||
    //     location.pathname === "/employee/partner-list",
    //   elements: [
    //     {
    //       label: "Partner onboarding",
    //       active:
    //         location.pathname === "/employee/initiate-partner-on-boarding",
    //       handleMenu: () => {
    //         dispatch(push("/employee/initiate-partner-on-boarding"));
    //       },
    //       visible:
    //         roles?.includes("sales") ||
    //         roles?.includes("sales head") ||
    //         roles?.includes("area manager"),
    //     },
    //     {
    //       label: "Partner Status",
    //       active: location.pathname === "/employee/partner-status",
    //       handleMenu: () => {
    //         dispatch(push("/employee/partner-status"));
    //       },
    //       visible:
    //         roles?.includes("sales") ||
    //         roles?.includes("sales head") ||
    //         roles?.includes("accounts"),
    //     },
    //     {
    //       label: "Partner List",
    //       active: location.pathname === "/employee/partner-list",
    //       handleMenu: () => {
    //         dispatch(push("/employee/partner-list"));
    //       },
    //       visible: true,
    //     },
    //   ],
    // },
    {
      title: "Area Manager",
      visible:
        roles?.includes("area manager") || roles?.includes("store manager"),

      icon: areaManagerIcon,
      active:
        location.pathname === "/employee/partner-approval-area-manager" ||
        location.pathname === "/employee/partner/rca" ||
        location.pathname === "/employee/sales/daily-ops" ||
        location.pathname === "/employee/partner-status-area-manager",
      elements: [
        {
          label: "Partner approval",
          active:
            location.pathname === "/employee/partner-approval-area-manager",
          handleMenu: () => {
            dispatch(push("/employee/partner-approval-area-manager"));
          },
          visible: roles?.includes("area manager"),
        },
        {
          label: "Partner Status",
          active: location.pathname === "/employee/partner-status-area-manager",
          handleMenu: () => {
            dispatch(push("/employee/partner-status-area-manager"));
          },
          visible: roles?.includes("area manager"),
        },
        {
          label: "RCA",
          active: location.pathname === "/employee/partner/rca",
          handleMenu: () => {
            dispatch(push("/employee/partner/rca"));
          },

          visible:
            roles?.includes("area manager") || roles?.includes("store manager"),
        },
        {
          label: "Daily Ops",
          active: location.pathname === "/employee/sales/daily-ops",
          handleMenu: () => {
            dispatch(push("/employee/sales/daily-ops"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("area manager"),
        },
      ],
    },
    // {
    //   title: "Operations",
    //   visible: roles?.includes("operations head"),
    //   icon: opsIcon,
    //   active:
    //     location.pathname === "/employee/partner-approval-operations-head",
    //   elements: [
    //     {
    //       label: "Partner approval",
    //       active:
    //         location.pathname === "/employee/partner-approval-operations-head",
    //       handleMenu: () => {
    //         dispatch(push("/employee/partner-approval-operations-head"));
    //       },
    //       visible: roles?.includes("operations head"),
    //     },
    //   ],
    // },
    // {
    //   title: "Aggregator",
    //   visible:
    //     roles?.includes("admin") ||
    //     roles?.includes("aggregator") ||
    //     roles?.includes("area manager"),
    //   icon: aggregatorIcon,
    //   active:
    //     location.pathname === "/employee/aggregator-onboarding" ||
    //     location.pathname === "/employee/fssai-expiry" ||
    //     location.pathname === "/employee/aggregator-discount" ||
    //     location.pathname === "/employee/manage-mails" ||
    //     location.pathname === "/employee/launch-tracker" ||
    //     // location.pathname === "/employee/rista-mapping" ||
    //     location.pathname === "/employee/aggregation-mail-details" ||
    //     location.pathname === "/employee/partner/forecast" ||
    //     location.pathname === "/employee/partner/learning-resources" ||
    //     location.pathname === "/employee/zs-daily" ||
    //     location.pathname === "/employee/new-brand-addition" ||
    //     location.pathname === "/employee/wp-messages" ||
    //     location.pathname === "/employee/cpc" ||
    //     location.pathname === "/employee/cpc-requests" ||
    //     location.pathname === "/employee/pre-launch-summary" ||
    //     location.pathname === "/employee/po-details",
    //   elements: [
    //     {
    //       label: "Partner Onboarding/Training",
    //       active: location.pathname === "/employee/aggregator-onboarding",
    //       handleMenu: () => {
    //         dispatch(push("/employee/aggregator-onboarding"));
    //       },
    //       visible: roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "FSSAI expiry",
    //       active: location.pathname === "/employee/fssai-expiry",
    //       handleMenu: () => {
    //         dispatch(push("/employee/fssai-expiry"));
    //       },
    //       visible:
    //         roles?.includes("aggregator") || roles?.includes("area manager"),
    //     },
    //     {
    //       label: "Partner Discounts",
    //       active: location.pathname === "/employee/aggregator-discount",
    //       handleMenu: () => {
    //         dispatch(push("/employee/aggregator-discount"));
    //       },
    //       visible: roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "Manage Mails",
    //       active: location.pathname === "/employee/manage-mails",
    //       handleMenu: () => {
    //         dispatch(push("/employee/manage-mails"));
    //       },
    //       visible: roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "Launch Tracker",
    //       active: location.pathname === "/employee/launch-tracker",
    //       handleMenu: () => {
    //         dispatch(push("/employee/launch-tracker"));
    //       },
    //       visible: roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "Pre-Launch Summary",
    //       active: location.pathname === "/employee/pre-launch-summary",
    //       handleMenu: () => {
    //         dispatch(push("/employee/pre-launch-summary"));
    //       },
    //       visible: roles?.includes("aggregator"),
    //     },
    //     // {
    //     //   label: "Rista Mapping",
    //     //   active: location.pathname === "/employee/rista-mapping",
    //     //   handleMenu: () => {
    //     //     dispatch(push("/employee/rista-mapping"));
    //     //   },
    //     //   visible: roles?.includes("aggregator"),
    //     // },
    //     {
    //       label: "Aggregation mail details",
    //       active: location.pathname === "/employee/aggregation-mail-details",
    //       handleMenu: () => {
    //         dispatch(push("/employee/aggregation-mail-details"));
    //       },
    //       visible: roles?.includes("admin") || roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "PO Details",
    //       active: location.pathname === "/employee/po-details",
    //       handleMenu: () => {
    //         dispatch(push("/employee/po-details"));
    //       },
    //       visible:
    //         roles?.includes("admin") ||
    //         roles?.includes("aggregator") ||
    //         roles?.includes("area manager"),
    //     },
    //     {
    //       label: "CPC Requests",
    //       active: location.pathname === "/employee/cpc-requests",
    //       handleMenu: () => {
    //         dispatch(push("/employee/cpc-requests"));
    //       },
    //       visible: roles?.includes("admin"),
    //     },
    //     {
    //       label: "Partner Sales Forecast",
    //       active: location.pathname === "/employee/partner/forecast",
    //       handleMenu: () => {
    //         dispatch(push("/employee/partner/forecast"));
    //       },
    //       visible:
    //         roles?.includes("area manager") || roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "Learning Resources",
    //       active: location.pathname === "/employee/partner/learning-resources",
    //       handleMenu: () => {
    //         dispatch(push("/employee/partner/learning-resources"));
    //       },
    //       visible:
    //         roles?.includes("area manager") || roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "ZS Daily",
    //       active: location.pathname === "/employee/zs-daily",
    //       handleMenu: () => {
    //         dispatch(push("/employee/zs-daily"));
    //       },
    //       visible: roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "CPC",
    //       active: location.pathname === "/employee/cpc",
    //       handleMenu: () => {
    //         dispatch(push("/employee/cpc"));
    //       },
    //       visible: roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "New Brand Addition",
    //       active: location.pathname === "/employee/new-brand-addition",
    //       handleMenu: () => {
    //         dispatch(push("/employee/new-brand-addition"));
    //       },
    //       visible: roles?.includes("aggregator"),
    //     },
    //     {
    //       label: "Whatsapp Messages",
    //       active: location.pathname === "/employee/wp-messages",
    //       handleMenu: () => {
    //         dispatch(push("/employee/wp-messages"));
    //       },
    //       visible: roles?.includes("aggregator"),
    //     },
    //   ],
    // },
    // {
    //   title: "Inventory",
    //   visible:
    //     roles?.includes("inventory") ||
    //     roles?.includes("admin") ||
    //     roles?.includes("HP Warehouse"),
    //   icon: marketingIcon,
    //   active:
    //     location.pathname === "/employee/inventory/configure-stock" ||
    //     location.pathname === "/employee/inventory/payment-confirmation" ||
    //     location.pathname === "/employee/inventory/product" ||
    //     location.pathname === "/employee/inventory/orders" ||
    //     location.pathname === "/employee/inventory/suppliers" ||
    //     location.pathname === "/employee/inventory/inventory/dn/grn" ||
    //     location.pathname === "/employee/inventory/wip" ||
    //     location.pathname === "/employee/inventory/delivery-notes",
    //   elements: [
    //     // {
    //     //   label: "Configure stock",
    //     //   active: location.pathname === "/employee/inventory/configure-stock",
    //     //   handleMenu: () => {
    //     //     dispatch(push("/employee/inventory/configure-stock"));
    //     //   },
    //     //   visible: roles?.includes("inventory"),
    //     // },

    //     {
    //       label: "Products",
    //       active: location.pathname === "/employee/inventory/product",
    //       handleMenu: () => {
    //         dispatch(push("/employee/inventory/product"));
    //       },
    //       visible: roles?.includes("inventory"),
    //     },
    //     {
    //       label: "Suppliers",
    //       active: location.pathname === "/employee/inventory/suppliers",
    //       handleMenu: () => {
    //         dispatch(push("/employee/inventory/suppliers"));
    //       },
    //       visible: roles?.includes("inventory"),
    //     },
    //     {
    //       label: "Orders",
    //       active: location.pathname === "/employee/inventory/orders",
    //       handleMenu: () => {
    //         dispatch(push("/employee/inventory/orders"));
    //       },
    //       visible: roles?.includes("inventory"),
    //     },
    //     {
    //       label: "Delivery Notes",
    //       active: location.pathname === "/employee/inventory/delivery-notes",
    //       handleMenu: () => {
    //         dispatch(push("/employee/inventory/delivery-notes"));
    //       },
    //       visible:
    //         roles?.includes("inventory") || roles?.includes("HP Warehouse"),
    //     },
    //     {
    //       label: "WIP",
    //       active: location.pathname === "/employee/inventory/wip",
    //       handleMenu: () => {
    //         dispatch(push("/employee/inventory/wip"));
    //       },
    //       visible: roles?.includes("inventory"),
    //     },

    //     {
    //       label: "Inventory/DN/GRN",
    //       active: location.pathname === "/employee/inventory/inventory/dn/grn",
    //       handleMenu: () => {
    //         dispatch(push("/employee/inventory/inventory/dn/grn"));
    //       },
    //       visible: roles?.includes("inventory"),
    //     },
    //     {
    //       label: "PO Credit Approval",
    //       active:
    //         location.pathname === "/employee/inventory/payment-confirmation",
    //       handleMenu: () => {
    //         dispatch(push("/employee/inventory/payment-confirmation"));
    //       },
    //       visible: roles?.includes("admin"),
    //     },
    //   ],
    // },
    {
      title: "Analytics",
      visible:
        roles?.includes("aggregator") ||
        roles?.includes("operations head") ||
        roles?.includes("admin") ||
        roles?.includes("accounts") ||
        roles?.includes("sales head") ||
        roles?.includes("store manager") ||
        roles?.includes("area manager"),
      icon: analyticsIcon,
      active:
        location.pathname === "/employee/sales/overview" ||
        location.pathname === "/employee/sales-summary" ||
        location.pathname === "/employee/sales/normalised" ||
        location.pathname === "/employee/sales/ta-normalised" ||
        location.pathname === "/employee/sales/normalised/day-wise" ||
        location.pathname === "/employee/sales/partner/normalised" ||
        location.pathname === "/employee/sales/ir/normalised" ||
        location.pathname === "/employee/sales/brand/normalised" ||
        location.pathname === "/employee/sales/region" ||
        location.pathname === "/employee/sales/sales-by-brand" ||
        location.pathname === "/employee/sales/sales-by-state" ||
        location.pathname === "/employee/sales/hourly-sales" ||
        location.pathname === "/employee/item-stock/item-in-stock" ||
        location.pathname === "/employee/item-stock/oos-analysis" ||
        location.pathname === "/employee/item-stock/oos-sale-loss" ||
        location.pathname === "/employee/sales/timeslot" ||
        location.pathname === "/employee/sales/restaurant/health" ||
        location.pathname === "/employee/sales/restaurant/analytics" ||
        location.pathname === "/employee/payout-earnings" ||
        location.pathname === "/employee/sales/food-costing" ||
        location.pathname === "/employee/sales/profit-center" ||
        location.pathname === "/employee/sales/visibility" ||
        location.pathname === "/employee/partner/analytics" ||
        location.pathname === "/employee/partner/report-card" ||
        location.pathname === "/employee/sales/crossed-kpt-rwt" ||
        location.pathname === "/employee/sales/oos" ||
        location.pathname === "/employee/sales/oos-wip" ||
        location.pathname === "/employee/sales/review-analysis" ||
        location.pathname === "/employee/sales/reviews" ||
        location.pathname === "/employee/sales/logs" ||
        location.pathname === "/employee/sales/restaurant-health/daily-ops" ||
        location.pathname === "/employee/zs-upload" ||
        location.pathname === "/employee/zs-summary" ||
        location.pathname === "/employee/partner/gkscores" ||
        location.pathname === "/employee/partner/discount" ||
        location.pathname === "/employee/sales-performance" ||
        location.pathname === "/employee/igcc-upload" ||
        location.pathname === "/employee/zs-partner-health" ||
        location.pathname === "/employee/zs-partner-health-details" ||
        location.pathname === "/employee/daily-ops/wip" ||
        location.pathname === "/employee/zs-partner-kpt-rwt",
      elements: [
        {
          label: "Normalised",
          active:
            location.pathname === "/employee/sales/normalised" ||
            location.pathname === "/employee/sales/ta-normalised" ||
            location.pathname === "/employee/sales/normalised/day-wise" ||
            location.pathname === "/employee/sales/partner/normalised" ||
            location.pathname === "/employee/sales/ir/normalised" ||
            location.pathname === "/employee/sales/brand/normalised",
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          items: [
            {
              label: "Normalised Sale",
              active: location.pathname === "/employee/sales/normalised",
              handleMenu: () => {
                dispatch(push("/employee/sales/normalised"));
              },
            },
            {
              label: "TA Normalised",
              active: location.pathname === "/employee/sales/ta-normalised",
              handleMenu: () => {
                dispatch(push("/employee/sales/ta-normalised"));
              },
            },
            {
              label: "Day wise Sale",
              active:
                location.pathname === "/employee/sales/normalised/day-wise",
              handleMenu: () => {
                dispatch(push("/employee/sales/normalised/day-wise"));
              },
            },
            {
              label: "Partner Normalised",
              active:
                location.pathname === "/employee/sales/partner/normalised",
              handleMenu: () => {
                dispatch(push("/employee/sales/partner/normalised"));
              },
            },
            {
              label: "IR Normalised",
              active: location.pathname === "/employee/sales/ir/normalised",
              handleMenu: () => {
                dispatch(push("/employee/sales/ir/normalised"));
              },
            },
            {
              label: "Brand Normalised",
              active: location.pathname === "/employee/sales/brand/normalised",
              handleMenu: () => {
                dispatch(push("/employee/sales/brand/normalised"));
              },
            },
          ],
        },
        {
          label: "Sales",
          active:
            location.pathname === "/employee/sales/region" ||
            location.pathname === "/employee/sales/sales-by-brand" ||
            location.pathname === "/employee/sales/sales-by-state" ||
            location.pathname === "/employee/sales/overview" ||
            location.pathname === "/employee/sales-summary" ||
            location.pathname === "/employee/sales/hourly-sales" ||
            location.pathname === "/employee/sales/timeslot" ||
            location.pathname === "/employee/sales-performance",
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          items: [
            {
              label: "Sales Overview",
              active: location.pathname === "/employee/sales/overview",
              handleMenu: () => {
                dispatch(push("/employee/sales/overview"));
              },
            },
            {
              label: "Sales Summary",
              active: location.pathname === "/employee/sales-summary",
              handleMenu: () => {
                dispatch(push("/employee/sales-summary"));
              },
            },
            {
              label: "Sales Performance",
              active: location.pathname === "/employee/sales-performance",
              handleMenu: () => {
                dispatch(push("/employee/sales-performance"));
              },
            },
            {
              label: "Sales By Region",
              active: location.pathname === "/employee/sales/region",
              handleMenu: () => {
                dispatch(push("/employee/sales/region"));
              },
            },
            {
              label: "Sales By Brand",
              active: location.pathname === "/employee/sales/sales-by-brand",
              handleMenu: () => {
                dispatch(push("/employee/sales/sales-by-brand"));
              },
            },
            {
              label: "Sales By State",
              active: location.pathname === "/employee/sales/sales-by-state",
              handleMenu: () => {
                dispatch(push("/employee/sales/sales-by-state"));
              },
            },
            {
              label: "Hourly sales",
              active: location.pathname === "/employee/sales/hourly-sales",
              handleMenu: () => {
                dispatch(push("/employee/sales/hourly-sales"));
              },
            },
            {
              label: "Timeslot Analysis",
              active: location.pathname === "/employee/sales/timeslot",
              handleMenu: () => {
                dispatch(push("/employee/sales/timeslot"));
              },
            },
          ],
        },
        {
          label: "Item Stock",
          active:
            location.pathname === "/employee/item-stock/item-in-stock" ||
            location.pathname === "/employee/item-stock/oos-analysis" ||
            location.pathname === "/employee/item-stock/oos-sale-loss",
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          items: [
            {
              label: "Item in stock",
              active:
                location.pathname === "/employee/item-stock/item-in-stock",
              handleMenu: () => {
                dispatch(push("/employee/item-stock/item-in-stock"));
              },
            },
            {
              label: "OOS Analysis",
              active: location.pathname === "/employee/item-stock/oos-analysis",
              handleMenu: () => {
                dispatch(push("/employee/item-stock/oos-analysis"));
              },
            },
            {
              label: "OOS Sale loss",
              active:
                location.pathname === "/employee/item-stock/oos-sale-loss",
              handleMenu: () => {
                dispatch(push("/employee/item-stock/oos-sale-loss"));
              },
            },
          ],
        },
        {
          label: "Restaurant Health",
          active:
            location.pathname === "/employee/sales/restaurant/health" ||
            location.pathname === "/employee/payout-earnings" ||
            location.pathname === "/employee/sales/restaurant/analytics" ||
            location.pathname ===
              "/employee/sales/restaurant-health/daily-ops" ||
            location.pathname === "/employee/sales/visibility" ||
            location.pathname === "/employee/partner/report-card" ||
            location.pathname === "/employee/sales/food-costing" ||
            location.pathname === "/employee/sales/profit-center" ||
            location.pathname === "/employee/sales/crossed-kpt-rwt" ||
            location.pathname === "/employee/sales/oos" ||
            location.pathname === "/employee/sales/oos-wip" ||
            location.pathname === "/employee/daily-ops/wip" ||
            location.pathname === "/employee/zs-partner-kpt-rwt",

          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("area manager"),
          items: [
            {
              label: "Health Metrics",
              active: location.pathname === "/employee/sales/restaurant/health",
              handleMenu: () => {
                dispatch(push("/employee/sales/restaurant/health"));
              },
              visible: roles?.includes("store manager"),
            },
            {
              label: "Sales Analytics",
              active:
                location.pathname === "/employee/sales/restaurant/analytics",
              handleMenu: () => {
                dispatch(push("/employee/sales/restaurant/analytics"));
              },
              visible: roles?.includes("store manager"),
            },
            {
              label: "Food Costing",
              active: location.pathname === "/employee/sales/food-costing",
              handleMenu: () => {
                dispatch(push("/employee/sales/food-costing"));
              },
              visible: roles?.includes("store manager"),
            },
            {
              label: "Profit Center",
              active: location.pathname === "/employee/sales/profit-center",
              handleMenu: () => {
                dispatch(push("/employee/sales/profit-center"));
              },
              visible: roles?.includes("store manager"),
            },
            // {
            //   label: "Visibility",
            //   active: location.pathname === "/employee/sales/visibility",
            //   handleMenu: () => {
            //     dispatch(push("/employee/sales/visibility"));
            //   },
            //   visible: roles?.includes("store manager"),
            // },
            {
              label: "Report Card",
              active: location.pathname === "/employee/partner/report-card",
              handleMenu: () => {
                dispatch(push("/employee/partner/report-card"));
              },
              visible: roles?.includes("store manager"),
            },
            {
              label: "Payout Earnings",
              active: location.pathname === "/employee/payout-earnings",
              handleMenu: () => {
                dispatch(push("/employee/payout-earnings"));
              },
              visible: roles?.includes("store manager"),
            },
            {
              label: "Daily Ops",
              active:
                location.pathname ===
                "/employee/sales/restaurant-health/daily-ops",
              handleMenu: () => {
                dispatch(push("/employee/sales/restaurant-health/daily-ops"));
              },
              visible:
                roles?.includes("aggregator") ||
                roles?.includes("operations head") ||
                roles?.includes("admin") ||
                roles?.includes("accounts") ||
                roles?.includes("sales head") ||
                roles?.includes("store manager") ||
                roles?.includes("area manager"),
            },
            {
              label: "DO WIP",
              active: location.pathname === "/employee/daily-ops/wip",
              handleMenu: () => {
                dispatch(push("/employee/daily-ops/wip"));
              },
              visible: roles?.includes("store manager"),
            },
            {
              label: "ZS Partner KPT/RWT",
              active: location.pathname === "/employee/zs-partner-kpt-rwt",
              handleMenu: () => {
                dispatch(push("/employee/zs-partner-kpt-rwt"));
              },
              visible: roles?.includes("store manager"),
            },
            {
              label: "Crossed KPT/RWT",
              active: location.pathname === "/employee/sales/crossed-kpt-rwt",
              handleMenu: () => {
                dispatch(push("/employee/sales/crossed-kpt-rwt"));
              },
              visible: roles?.includes("store manager"),
            },
            // {
            //   label: "OOS",
            //   active: location.pathname === "/employee/sales/oos",
            //   handleMenu: () => {
            //     dispatch(push("/employee/sales/oos"));
            //   },
            //   visible: roles?.includes("store manager"),
            // },
            // {
            //   label: "OOS-WIP",
            //   active: location.pathname === "/employee/sales/oos-wip",
            //   handleMenu: () => {
            //     dispatch(push("/employee/sales/oos-wip"));
            //   },
            //   visible: roles?.includes("store manager"),
            // },
            // {
            //   label: "payment",
            //   active: location.pathname === "/employee/sales/payment",
            //   handleMenu: () => {
            //     dispatch(push("/employee/sales/payment"));
            //   },
            // },
          ],
        },
        // {
        //   label: "Review Management",
        //   active:
        //     location.pathname === "/employee/sales/review-analysis" ||
        //     location.pathname === "/employee/sales/logs" ||
        //     location.pathname === "/employee/sales/reviews",
        //   visible:
        //     roles?.includes("aggregator") ||
        //     roles?.includes("operations head") ||
        //     roles?.includes("admin") ||
        //     roles?.includes("accounts") ||
        //     roles?.includes("sales head") ||
        //     roles?.includes("store manager") ||
        //     roles?.includes("area manager"),
        //   items: [
        //     {
        //       label: "Dashboard",
        //       active: location.pathname === "/employee/sales/review-analysis",
        //       handleMenu: () => {
        //         dispatch(push("/employee/sales/review-analysis"));
        //       },
        //     },
        //     {
        //       label: "Reviews",
        //       active: location.pathname === "/employee/sales/reviews",
        //       handleMenu: () => {
        //         dispatch(push("/employee/sales/reviews"));
        //       },
        //     },
        //     {
        //       label: "Logs",
        //       active: location.pathname === "/employee/sales/logs",
        //       handleMenu: () => {
        //         dispatch(push("/employee/sales/logs"));
        //       },
        //     },
        //   ],
        // },
        {
          label: "Partner Analytics",
          active: location.pathname === "/employee/partner/analytics",
          handleMenu: () => {
            dispatch(push("/employee/partner/analytics"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
        },
        {
          label: "ZS Metrics",
          active:
            location.pathname === "/employee/zs-upload" ||
            location.pathname === "/employee/zs-summary" ||
            location.pathname === "/employee/igcc-upload" ||
            location.pathname === "/employee/zs-partner-health" ||
            location.pathname === "/employee/zs-partner-health-details",
          visible: roles?.includes("aggregator"),
          items: [
            {
              label: "ZS Upload",
              active: location.pathname === "/employee/zs-upload",
              handleMenu: () => {
                dispatch(push("/employee/zs-upload"));
              },
            },
            {
              label: "ZS Summary",
              active: location.pathname === "/employee/zs-summary",
              handleMenu: () => {
                dispatch(push("/employee/zs-summary"));
              },
            },
            {
              label: "IGCC Upload",
              active: location.pathname === "/employee/igcc-upload",
              handleMenu: () => {
                dispatch(push("/employee/igcc-upload"));
              },
            },
            {
              label: "ZS Partner Health",
              active: location.pathname === "/employee/zs-partner-health",
              handleMenu: () => {
                dispatch(push("/employee/zs-partner-health"));
              },
            },
            {
              label: "ZS Partner Health Details",
              active:
                location.pathname === "/employee/zs-partner-health-details",
              handleMenu: () => {
                dispatch(push("/employee/zs-partner-health-details"));
              },
            },
          ],
        },
        {
          label: "Partner Ranking",
          active: location.pathname === "/employee/partner/gkscores",
          handleMenu: () => {
            dispatch(push("/employee/partner/gkscores"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
        },
        {
          label: "Discount",
          active: location.pathname === "/employee/partner/discount",
          handleMenu: () => {
            dispatch(push("/employee/partner/discount"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
        },
      ],
    },
    // {
    //   title: "Marketing",
    //   visible:
    //     roles?.includes("marketing") ||
    //     roles?.includes("sales head") ||
    //     roles?.includes("sales") ||
    //     roles?.includes("area manager") ||
    //     roles?.includes("accounts"),
    //   icon: marketingIcon,
    //   active:
    //     location.pathname === "/employee/marketing-leads-upload" ||
    //     location.pathname === "/employee/lead-gens" ||
    //     location.pathname === "/employee/blogger-order-charges" ||
    //     location.pathname === "/employee/marketing-leads" ||
    //     location.pathname === "/employee/marketing-leads-info" ||
    //     location.pathname === "/employee/marketing-stories" ||
    //     location.pathname === "/employee/blogger-order" ||
    //     location.pathname === "/employee/marketing-lead-report",
    //   elements: [
    //     {
    //       label: "Upload marketing leads",
    //       active: location.pathname === "/employee/marketing-leads-upload",
    //       handleMenu: () => {
    //         dispatch(push("/employee/marketing-leads-upload"));
    //       },
    //       visible: roles?.includes("marketing"),
    //     },
    //     {
    //       label: "Marketing leads",
    //       active: location.pathname === "/employee/marketing-leads",
    //       handleMenu: () => {
    //         dispatch(push("/employee/marketing-leads"));
    //       },
    //       visible: roles?.includes("sales head"),
    //     },
    //     {
    //       label: "Marketing leads info",
    //       active: location.pathname === "/employee/marketing-leads-info",
    //       handleMenu: () => {
    //         dispatch(push("/employee/marketing-leads-info"));
    //       },
    //       visible: roles?.includes("sales"),
    //     },
    //     {
    //       label: "Marketing Stories",
    //       active: location.pathname === "/employee/marketing-stories",
    //       handleMenu: () => {
    //         dispatch(push("/employee/marketing-stories"));
    //       },
    //       visible: roles?.includes("marketing"),
    //     },
    //     {
    //       label: "Blogger Order",
    //       active: location.pathname === "/employee/blogger-order",
    //       handleMenu: () => {
    //         dispatch(push("/employee/blogger-order"));
    //       },
    //       visible:
    //         roles?.includes("marketing") ||
    //         roles?.includes("area manager") ||
    //         roles?.includes("accounts"),
    //     },
    //     {
    //       label: "Marketing leads report",
    //       active: location.pathname === "/employee/marketing-lead-report",
    //       handleMenu: () => {
    //         dispatch(push("/employee/marketing-lead-report"));
    //       },
    //       visible:
    //         roles?.includes("marketing") ||
    //         roles?.includes("sales") ||
    //         roles?.includes("sales head"),
    //     },
    //     {
    //       label: "Blogger Order Charges",
    //       active: location.pathname === "/employee/blogger-order-charges",
    //       handleMenu: () => {
    //         dispatch(push("/employee/blogger-order-charges"));
    //       },
    //       visible:
    //         roles?.includes("marketing") ||
    //         roles?.includes("sales") ||
    //         roles?.includes("sales head"),
    //     },
    //     {
    //       label: "Lead Gen",
    //       active: location.pathname === "/employee/lead-gens",
    //       handleMenu: () => {
    //         dispatch(push("/employee/lead-gens"));
    //       },
    //       visible:
    //         roles?.includes("marketing") ||
    //         roles?.includes("sales") ||
    //         roles?.includes("sales head"),
    //     },
    //   ],
    // },
    {
      title: "Accounts",
      visible:
        roles?.includes("accounts") ||
        roles?.includes("sales") ||
        roles?.includes("sales head") ||
        roles?.includes("admin") ||
        roles?.includes("purchase") ||
        roles?.includes("area manager"),
      icon: accountsIcon,
      active:
        location.pathname === "/employee/partner-payments" ||
        location.pathname === "/employee/employee-bank-details" ||
        location.pathname === "/employee/partner-bank-details" ||
        location.pathname === "/employee/payout-reports" ||
        location.pathname === "/employee/payout-summary" ||
        location.pathname === "/employee/edit-request" ||
        location.pathname === "/employee/revenue-share" ||
        location.pathname === "/employee/razorpay-recon" ||
        location.pathname === "/employee/sales/payment" ||
        location.pathname === "/employee/zs-finance" ||
        location.pathname === "/employee/alternate-rista" ||
        location.pathname === "/employee/aggregator-recon" ||
        location.pathname === "/employee/pettycash/responses" ||
        location.pathname === "/employee/gk_investments" ||
        location.pathname === "/employee/account/inventory" ||
        location.pathname === "/employee/rupifi/gmv" ||
        location.pathname === "/employee/cpc/approved" ||
        location.pathname === "/employee/config/software-fees" ||
        location.pathname === "/employee/config/brand-addition-requests" ||
        location.pathname === "/employee/config" ||
        location.pathname === "/employee/invoice/payments" ||
        location.pathname === "/employee/finance-report-upload" ||
        location.pathname === "/employee/tax-report-upload" ||
        location.pathname === "/employee/adjustment-report-upload" ||
        location.pathname === "/employee/ledger-summary" ||
        location.pathname === "/employee/ledger-upload-or-view" ||
        location.pathname === "/employee/payout-transactions" ||
        location.pathname === "/employee/payout-downloads" ||
        location.pathname === "/employee/blogger-order-reco" ||
        location.pathname === "/employee/cashback" ||
        location.pathname === "/employee/cancelled-payout" ||
        location.pathname === "/employee/estimated-fund-inflow" ||
        location.pathname === "/employee/payout-deduction-summary" ||
        location.pathname === "/employee/new-brands" ||
        location.pathname === "/employee/dot-pay-reco" ||
        location.pathname === "/employee/gk-margins" ||
        location.pathname === "/employee/Inventory-DN-GRM",
      elements: [
        {
          label: "Partner payments",
          active: location.pathname === "/employee/partner-payments",
          handleMenu: () => {
            dispatch(push("/employee/partner-payments"));
          },
          visible:
            roles?.includes("accounts") ||
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("purchase") ||
            roles?.includes("area manager"),
        },
        {
          label: "Bank details",
          active:
            location.pathname === "/employee/employee-bank-details" ||
            location.pathname === "/employee/partner-bank-details" ||
            location.pathname === "/employee/edit-request",
          visible: roles?.includes("accounts"),
          items: [
            {
              label: "Employee Bank details",
              active: location.pathname === "/employee/employee-bank-details",
              handleMenu: () => {
                dispatch(push("/employee/employee-bank-details"));
              },
            },
            {
              label: "Partner bank details",
              active: location.pathname === "/employee/partner-bank-details",
              handleMenu: () => {
                dispatch(push("/employee/partner-bank-details"));
              },
            },
            {
              label: "Edit Request",
              active: location.pathname === "/employee/edit-request",
              handleMenu: () => {
                dispatch(push("/employee/edit-request"));
              },
            },
          ],
        },
        {
          label: "Payout Reports",
          active:
            location.pathname === "/employee/payout-reports" ||
            location.pathname === "/employee/payout-summary" ||
            location.pathname === "/employee/payout-transactions" ||
            location.pathname === "/employee/payout-downloads" ||
            location.pathname === "/employee/cancelled-payout" ||
            location.pathname === "/employee/blogger-order-reco" ||
            location.pathname === "/employee/cashback" ||
            location.pathname === "/employee/payout-deduction-summary",
          visible:
            roles?.includes("accounts") || roles?.includes("area manager"),
          items: [
            {
              label: "Payment Schedules",
              active: location.pathname === "/employee/payout-reports",
              handleMenu: () => {
                dispatch(push("/employee/payout-reports"));
              },
            },
            {
              label: "Payout Summary",
              active: location.pathname === "/employee/payout-summary",
              handleMenu: () => {
                dispatch(push("/employee/payout-summary"));
              },
            },
            {
              label: "Deduction Summary",
              active:
                location.pathname === "/employee/payout-deduction-summary",
              handleMenu: () => {
                dispatch(push("/employee/payout-deduction-summary"));
              },
            },
            {
              label: "Payout Transactions",
              active: location.pathname === "/employee/payout-transactions",
              handleMenu: () => {
                dispatch(push("/employee/payout-transactions"));
              },
            },
            {
              label: "Payout Downloads",
              active: location.pathname === "/employee/payout-downloads",
              handleMenu: () => {
                dispatch(push("/employee/payout-downloads"));
              },
            },
            {
              label: "Cancelled Orders",
              active: location.pathname === "/employee/cancelled-payout",
              handleMenu: () => {
                dispatch(push("/employee/cancelled-payout"));
              },
            },
            {
              label: "Blogger Order Reco",
              active: location.pathname === "/employee/blogger-order-reco",
              handleMenu: () => {
                dispatch(push("/employee/blogger-order-reco"));
              },
            },
            {
              label: "Cash Back",
              active: location.pathname === "/employee/cashback",
              handleMenu: () => {
                dispatch(push("/employee/cashback"));
              },
            },
          ],
        },

        {
          label: "Recon",
          active:
            location.pathname === "/employee/sales/payment" ||
            location.pathname === "/employee/razorpay-recon" ||
            location.pathname === "/employee/alternate-rista" ||
            location.pathname === "/employee/aggregator-recon" ||
            location.pathname === "/employee/estimated-fund-inflow" ||
            location.pathname === "/employee/new-brands" ||
            location.pathname === "/employee/dot-pay-reco" ||
            location.pathname === "/employee/gk-margins" ||
            location.pathname === "/employee/zs-finance" ||
            location.pathname === "/employee/Inventory-DN-GRM",
          visible:
            roles?.includes("accounts") ||
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          items: [
            {
              label: "UPI Recon",
              active: location.pathname === "/employee/sales/payment",
              handleMenu: () => {
                dispatch(push("/employee/sales/payment"));
              },
            },
            {
              label: "Razorpay Recon",
              active: location.pathname === "/employee/razorpay-recon",
              handleMenu: () => {
                dispatch(push("/employee/razorpay-recon"));
              },
            },
            {
              label: "Alternate Rista",
              active: location.pathname === "/employee/alternate-rista",
              handleMenu: () => {
                dispatch(push("/employee/alternate-rista"));
              },
            },
            {
              label: "Aggregator Recon",
              active: location.pathname === "/employee/aggregator-recon",
              handleMenu: () => {
                dispatch(push("/employee/aggregator-recon"));
              },
            },
            {
              label: "Estimated fund inflow",
              active: location.pathname === "/employee/estimated-fund-inflow",
              handleMenu: () => {
                dispatch(push("/employee/estimated-fund-inflow"));
              },
            },
            {
              label: "New Brands",
              active: location.pathname === "/employee/new-brands",
              handleMenu: () => {
                dispatch(push("/employee/new-brands"));
              },
            },
            {
              label: "Dotpe Reco",
              active: location.pathname === "/employee/dot-pay-reco",
              handleMenu: () => {
                dispatch(push("/employee/dot-pay-reco"));
              },
            },
            {
              label: "GK Margins",
              active: location.pathname === "/employee/gk-margins",
              handleMenu: () => {
                dispatch(push("/employee/gk-margins"));
              },
            },
            {
              label: "Inventory/DN/GRN",
              active: location.pathname === "/employee/Inventory-DN-GRM",
              handleMenu: () => {
                dispatch(push("/employee/Inventory-DN-GRM"));
              },
            },
            {
              label: "ZS Finance",
              active: location.pathname === "/employee/zs-finance",
              handleMenu: () => {
                dispatch(push("/employee/zs-finance"));
              },
            },
          ],
        },
        {
          label: "Reports",
          active:
            location.pathname === "/employee/finance-report-upload" ||
            location.pathname === "/employee/tax-report-upload" ||
            location.pathname === "/employee/adjustment-report-upload" ||
            location.pathname === "/employee/ledger-summary" ||
            location.pathname === "/employee/ledger-upload-or-view",
          visible: roles?.includes("accounts"),
          items: [
            {
              label: "Finance report upload",
              active: location.pathname === "/employee/finance-report-upload",
              handleMenu: () => {
                dispatch(push("/employee/finance-report-upload"));
              },
            },
            {
              label: "Tax report upload",
              active: location.pathname === "/employee/tax-report-upload",
              handleMenu: () => {
                dispatch(push("/employee/tax-report-upload"));
              },
            },
            {
              label: "Adjustment report upload",
              active:
                location.pathname === "/employee/adjustment-report-upload",
              handleMenu: () => {
                dispatch(push("/employee/adjustment-report-upload"));
              },
            },
            {
              label: "Ledger summary",
              active: location.pathname === "/employee/ledger-summary",
              handleMenu: () => {
                dispatch(push("/employee/ledger-summary"));
              },
            },
            {
              label: "Ledger upload/view",
              active: location.pathname === "/employee/ledger-upload-or-view",
              handleMenu: () => {
                dispatch(push("/employee/ledger-upload-or-view"));
              },
            },
          ],
        },

        {
          label: "Petty Cash Responses",
          active: location.pathname === "/employee/pettycash/responses",
          handleMenu: () => {
            dispatch(push("/employee/pettycash/responses"));
          },
          visible: roles?.includes("accounts"),
        },
        {
          label: "GK Investments",
          active: location.pathname === "/employee/gk_investments",
          handleMenu: () => {
            dispatch(push("/employee/gk_investments"));
          },
          visible:
            roles?.includes("marketing") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts"),
        },
        {
          label: "Rupifi GMV",
          active: location.pathname === "/employee/rupifi/gmv",
          handleMenu: () => {
            dispatch(push("/employee/rupifi/gmv"));
          },
          visible: roles?.includes("accounts"),
        },
        {
          label: "Invoices",
          active: location.pathname === "/employee/invoice/payments",
          handleMenu: () => {
            dispatch(push("/employee/invoice/payments"));
          },
          visible: roles?.includes("accounts"),
        },
        {
          label: "CPC",
          active: location.pathname === "/employee/cpc/approved",
          handleMenu: () => {
            dispatch(push("/employee/cpc/approved"));
          },
          visible: roles?.includes("accounts"),
        },
        {
          label: "Config",
          active:
            location.pathname === "/employee/config/software-fees" ||
            location.pathname === "/employee/config/brand-addition-requests" ||
            location.pathname === "/employee/revenue-share",
          visible: roles?.includes("accounts"),
          items: [
            {
              label: "Software fees",
              active: location.pathname === "/employee/config/software-fees",
              handleMenu: () => {
                dispatch(push("/employee/config/software-fees"));
              },
            },
            {
              label: "Revenue Share",
              active: location.pathname === "/employee/revenue-share",
              handleMenu: () => {
                dispatch(push("/employee/revenue-share"));
              },
              visible: roles?.includes("accounts"),
            },
            {
              label: "New Brand Requests",
              active:
                location.pathname ===
                "/employee/config/brand-addition-requests",
              handleMenu: () => {
                dispatch(push("/employee/config/brand-addition-requests"));
              },
            },
          ],
        },
        {
          label: "Inventory",
          active: location.pathname === "/employee/account/inventory",
          handleMenu: () => {
            dispatch(push("/employee/account/inventory"));
          },
          visible: roles?.includes("accounts"),
        },
      ],
    },
    // {
    //   title: "CRM",
    //   visible: roles?.includes("crm"),
    //   icon: crmIcon,
    //   active:
    //     location.pathname === "/employee/tickets-overview" ||
    //     location.pathname === "/employee/delayed-reports" ||
    //     location.pathname === "/employee/tickets-categories" ||
    //     location.pathname === "/employee/dashboard-access" ||
    //     location.pathname === "/employee/faq-bank" ||
    //     location.pathname === "/employee/news",
    //   elements: [
    //     {
    //       label: "Tickets overview",
    //       active: location.pathname === "/employee/tickets-overview",
    //       handleMenu: () => {
    //         dispatch(push("/employee/tickets-overview"));
    //       },
    //       visible: roles?.includes("crm"),
    //     },
    //     {
    //       label: "Delayed Reports",
    //       active: location.pathname === "/employee/delayed-reports",
    //       handleMenu: () => {
    //         dispatch(push("/employee/delayed-reports"));
    //       },
    //       visible: roles?.includes("crm"),
    //     },
    //     {
    //       label: "Manage News",
    //       active: location.pathname === "/employee/news",
    //       handleMenu: () => {
    //         dispatch(push("/employee/news"));
    //       },
    //       visible: roles?.includes("crm"),
    //     },
    //     {
    //       label: "FAQ Bank(WIP)",
    //       active: location.pathname === "/employee/faq-bank",
    //       handleMenu: () => {
    //         dispatch(push("/employee/faq-bank"));
    //       },
    //       visible: roles?.includes("crm"),
    //     },
    //     {
    //       label: "Ticket Category",
    //       active: location.pathname === "/employee/tickets-categories",
    //       handleMenu: () => {
    //         dispatch(push("/employee/tickets-categories"));
    //       },
    //       visible: roles?.includes("crm"),
    //     },
    //     {
    //       label: "Dashboard Access",
    //       active: location.pathname === "/employee/dashboard-access",
    //       handleMenu: () => {
    //         dispatch(push("/employee/dashboard-access"));
    //       },
    //       visible: roles?.includes("crm"),
    //     },
    //   ],
    // },
    {
      title: "Tickets",
      visible: true,
      icon: ticketsIcon,
      active:
        location.pathname === "/employee/all-tickets" ||
        location.pathname === "/employee/my-tickets" ||
        location.pathname === "/employee/faqs" ||
        location.pathname === "/employee/tech-support" ||
        location.pathname === "/employee/support-tracker" ||
        location.pathname === "/employee/assigned-tickets",
      elements: [
        {
          label: "All Tickets",
          active: location.pathname === "/employee/all-tickets",
          handleMenu: () => {
            dispatch(push(`/employee/all-tickets${location.search}`));
          },
          visible: roles?.includes("crm") || roles?.includes("area manager"),
        },
        {
          label: "My Tickets",
          active: location.pathname === "/employee/my-tickets",
          handleMenu: () => {
            dispatch(push("/employee/my-tickets"));
          },
          visible: true,
        },
        {
          label: "Assigned Tickets",
          active: location.pathname === "/employee/assigned-tickets",
          handleMenu: () => {
            dispatch(push("/employee/assigned-tickets"));
          },
          visible: true,
        },
        // {
        //   label: "Tickets Category",
        //   active: location.pathname === "/employee/tickets-categories",
        //   handleMenu: () => {
        //     dispatch(push("/employee/tickets-categories"));
        //   },
        //   visible: true,
        // },
        {
          label: "FAQs (WIP)",
          active: location.pathname === "/employee/faqs",
          handleMenu: () => {
            dispatch(push("/employee/faqs"));
          },
          visible: true,
        },
        {
          label: "Partner Tech Support",
          active: location.pathname === "/employee/tech-support",
          handleMenu: () => {
            dispatch(push("/employee/tech-support"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Support Tracker",
          active: location.pathname === "/employee/support-tracker",
          handleMenu: () => {
            dispatch(push("/employee/support-tracker"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
      ],
    },
    {
      title: "Optipro",
      visible: true,
      icon: crmIcon,
      active:
        location.pathname === "/employee/reviews" ||
        location.pathname === "/employee/tech-support/wip" ||
        location.pathname === "/employee/support-tracker/wip" ||
        location.pathname === "/employee/optipro/poor-review" ||
        location.pathname === "/employee/optipro/sales-dashboard" ||
        location.pathname === "/employee/hourly-sales" ||
        location.pathname === "/employee/mumbai-brands" ||
        location.pathname === "/employee/ahmedabad-brands" ||
        location.pathname === "/employee/daily-ops2" ||
        location.pathname === "/employee/live-tracking" ||
        location.pathname === "/employee/business-report" ||
        location.pathname === "/employee/sales-analytics" ||
        location.pathname === "/employee/optipro/oos" ||
        location.pathname === "/employee/optipro/visibility" ||
        location.pathname === "/employee/optipro/review-summary" ||
        location.pathname === "/employee/optipro/reviews" ||
        location.pathname === "/employee/optipro/logs" ||
        location.pathname === "/employee/optipro/onboarding" ||
        location.pathname === "/employee/optipro/schedulers" ||
        location.pathname === "/employee/optipro/confirm-schedulers" ||
        location.pathname === "/employee/optipro/order-level-data" ||
        location.pathname === "/employee/optipro/cron" ||
        location.pathname === "/employee/optipro/puppeteer" ||
        location.pathname === "/employee/optipro/puppeteer-last-run" ||
        location.pathname === "/employee/optipro/google-business-ids" ||
        location.pathname === "/employee/daily-ops",
      elements: [
        {
          label: "Reviews WIP",
          active: location.pathname === "/employee/reviews",
          handleMenu: () => {
            dispatch(push(`/employee/reviews`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("area manager") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("crm") ||
            roles?.includes("store manager"),
        },
        // {
        //   label: "Daily Ops",
        //   active: location.pathname === "/employee/daily-ops",
        //   handleMenu: () => {
        //     dispatch(push(`/employee/daily-ops`));
        //   },
        //   visible:
        //     roles?.includes("crm") ||
        //     roles?.includes("area manager") ||
        //     roles?.includes("crm") ||
        //     roles?.includes("accounts") ||
        //     roles?.includes("aggregator") ||
        //     roles?.includes("store manager"),
        // },
        {
          label: "Daily Ops-New",
          active: location.pathname === "/employee/daily-ops2",
          handleMenu: () => {
            dispatch(push(`/employee/daily-ops2`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        // {
        //   label: "Live Tracking",
        //   active: location.pathname === "/employee/live-tracking",
        //   handleMenu: () => {
        //     dispatch(push(`/employee/live-tracking`));
        //   },
        //   visible:
        //     roles?.includes("crm") ||
        //     roles?.includes("aggregator") ||
        //     roles?.includes("accounts") ||
        //     roles?.includes("area manager") ||
        //     roles?.includes("store manager"),
        // },
        {
          label: "Business Report",
          active: location.pathname === "/employee/business-report",
          handleMenu: () => {
            dispatch(push(`/employee/business-report`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Sales Analytics",
          active: location.pathname === "/employee/sales-analytics",
          handleMenu: () => {
            dispatch(push(`/employee/sales-analytics`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Partner Tech Support",
          active: location.pathname === "/employee/tech-support/wip",
          handleMenu: () => {
            dispatch(push(`/employee/tech-support/wip`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Support Tracker",
          active: location.pathname === "/employee/support-tracker/wip",
          handleMenu: () => {
            dispatch(push(`/employee/support-tracker/wip`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Poor Rated Reviews",
          active: location.pathname === "/employee/optipro/poor-review",
          handleMenu: () => {
            dispatch(push(`/employee/optipro/poor-review`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Sales Dashboard",
          active: location.pathname === "/employee/optipro/sales-dashboard",
          handleMenu: () => {
            dispatch(push(`/employee/optipro/sales-dashboard`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Hourly Sales",
          active: location.pathname === "/employee/hourly-sales",
          handleMenu: () => {
            dispatch(push(`/employee/hourly-sales`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Visibility",
          active: location.pathname === "/employee/optipro/visibility",
          handleMenu: () => {
            dispatch(push(`/employee/optipro/visibility`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "OOS",
          active: location.pathname === "/employee/optipro/oos",
          handleMenu: () => {
            dispatch(push(`/employee/optipro/oos`));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Review summary",
          active: location.pathname === "/employee/optipro/review-summary",
          handleMenu: () => {
            dispatch(push("/employee/optipro/review-summary"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Reviews",
          active: location.pathname === "/employee/optipro/reviews",
          handleMenu: () => {
            dispatch(push("/employee/optipro/reviews"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Logs",
          active: location.pathname === "/employee/optipro/logs",
          handleMenu: () => {
            dispatch(push("/employee/optipro/logs"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Onboarding",
          active: location.pathname === "/employee/optipro/onboarding",
          handleMenu: () => {
            dispatch(push("/employee/optipro/onboarding"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Schedulers",
          active: location.pathname === "/employee/optipro/schedulers",
          handleMenu: () => {
            dispatch(push("/employee/optipro/schedulers"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Confirm Schedulers",
          active: location.pathname === "/employee/optipro/confirm-schedulers",
          handleMenu: () => {
            dispatch(push("/employee/optipro/confirm-schedulers"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Order Level Data",
          active: location.pathname === "/employee/optipro/order-level-data",
          handleMenu: () => {
            dispatch(push("/employee/optipro/order-level-data"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        // {
        //   label: "Crons",
        //   active: location.pathname === "/employee/optipro/cron",
        //   handleMenu: () => {
        //     dispatch(push("/employee/optipro/cron"));
        //   },
        //   visible:
        //   roles?.includes("area manager") ||
        //     roles?.includes("tech"),
        // },
        {
          label: "Crons",
          active: location.pathname === "/employee/optipro/puppeteer",
          handleMenu: () => {
            dispatch(push("/employee/optipro/puppeteer"));
          },
          visible: roles?.includes("tech"),
        },
        {
          label: "Puppeteers Last Run",
          active: location.pathname === "/employee/optipro/puppeteer-last-run",
          handleMenu: () => {
            dispatch(push("/employee/optipro/puppeteer-last-run"));
          },
          visible: roles?.includes("tech"),
        },
        {
          label: "Google Business Ids",
          active: location.pathname === "/employee/optipro/google-business-ids",
          handleMenu: () => {
            dispatch(push("/employee/optipro/google-business-ids"));
          },
          visible: roles?.includes("tech"),
        },
        // {
        //   label: "OOS",
        //   active: location.pathname === "/employee/optipro/oos",
        //   handleMenu: () => {
        //     dispatch(push("/employee/optipro/oos"));
        //   },
        //   visible: roles?.includes("store manager"),
        // },
      ],
    },
    {
      title: "Admin View",
      visible: true,
      icon: extrasIcon,
      active:
        location.pathname === "/employee/admin-view/sales-analytics" ||
        location.pathname === "/employee/admin-view/business-report" ||
        location.pathname === "/employee/admin-view/sales-dashboard",
      elements: [
        {
          label: "Sales Analytics",
          active: location.pathname === "/employee/admin-view/sales-analytics",
          handleMenu: () => {
            dispatch(push("/employee/admin-view/sales-analytics"));
          },
          visible: true,
        },
        {
          label: "Sales Dashboard",
          active: location.pathname === "/employee/admin-view/sales-dashboard",
          handleMenu: () => {
            dispatch(push("/employee/admin-view/sales-dashboard"));
          },
          visible: true,
        },
        {
          label: "Business Report",
          active: location.pathname === "/employee/admin-view/business-report",
          handleMenu: () => {
            dispatch(push("/employee/admin-view/business-report"));
          },
          visible: true,
        },
      ],
    },
    // {
    //   title: "Extras",
    //   visible: true,
    //   icon: extrasIcon,
    //   active:
    //     location.pathname === "/employee/property" ||
    //     location.pathname === "/employee/department" ||
    //     location.pathname === "/employee/region" ||
    //     location.pathname === "/employee/brands" ||
    //     location.pathname === "/employee/document-uploader" ||
    //     location.pathname === "/employee/banners" ||
    //     location.pathname === "/employee/cuisine" ||
    //     location.pathname === "/employee/partner-bucket" ||
    //     location.pathname === "/employee/logs" ||
    //     location.pathname === "/employee/flags" ||
    //     location.pathname === "/employee/scheduler-logs" ||
    //     location.pathname === "/employee/upload-status",
    //   elements: [
    //     {
    //       label: "Property",
    //       visible: roles?.includes("admin"),
    //       active: location.pathname === "/employee/property",
    //       handleMenu: () => {
    //         dispatch(push("/employee/property"));
    //       },
    //     },
    //     {
    //       label: "Department",
    //       visible: roles?.includes("admin"),
    //       active: location.pathname === "/employee/department",
    //       handleMenu: () => {
    //         dispatch(push("/employee/department"));
    //       },
    //     },
    //     {
    //       label: "Region",
    //       visible: roles?.includes("admin") || roles?.includes("aggregator"),
    //       active: location.pathname === "/employee/region",
    //       handleMenu: () => {
    //         dispatch(push("/employee/region"));
    //       },
    //     },
    //     {
    //       label: "Brands",
    //       visible: roles?.includes("admin") || roles?.includes("aggregator"),
    //       active: location.pathname === "/employee/brands",
    //       handleMenu: () => {
    //         dispatch(push("/employee/brands"));
    //       },
    //     },
    //     {
    //       label: "Cuisine",
    //       visible: roles?.includes("admin") || roles?.includes("aggregator"),
    //       active: location.pathname === "/employee/cuisine",
    //       handleMenu: () => {
    //         dispatch(push("/employee/cuisine"));
    //       },
    //     },
    //     {
    //       label: "Partner Buckets",
    //       visible: roles?.includes("admin") || roles?.includes("aggregator"),
    //       active: location.pathname === "/employee/partner-bucket",
    //       handleMenu: () => {
    //         dispatch(push("/employee/partner-bucket"));
    //       },
    //     },
    //     {
    //       label: "Document Uploader",
    //       visible: roles?.includes("admin") || roles?.includes("aggregator"),
    //       active: location.pathname === "/employee/document-uploader",
    //       handleMenu: () => {
    //         dispatch(push("/employee/document-uploader"));
    //       },
    //     },
    //     {
    //       label: "Banners",
    //       visible: roles?.includes("admin"),
    //       active: location.pathname === "/employee/banners",
    //       handleMenu: () => {
    //         dispatch(push("/employee/banners"));
    //       },
    //     },
    //     {
    //       label: "Upload Status",
    //       visible: true,
    //       active: location.pathname === "/employee/upload-status",
    //       handleMenu: () => {
    //         dispatch(push("/employee/upload-status"));
    //       },
    //     },
    //     {
    //       label: "Logs",
    //       visible: true,
    //       active: location.pathname === "/employee/logs",
    //       handleMenu: () => {
    //         dispatch(push("/employee/logs"));
    //       },
    //     },
    //     {
    //       label: "Flags",
    //       visible: roles?.includes("admin"),
    //       active: location.pathname === "/employee/flags",
    //       handleMenu: () => {
    //         dispatch(push("/employee/flags"));
    //       },
    //     },
    //     {
    //       label: "Scheduler Logs",
    //       visible: roles?.includes("admin"),
    //       active: location.pathname === "/employee/scheduler-logs",
    //       handleMenu: () => {
    //         dispatch(push("/employee/scheduler-logs"));
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: "Academy",
    //   visible: true,
    //   icon: ticketsIcon,
    //   active:
    //     location.pathname === "/employee/academy" ||
    //     roles?.includes("area manager") ||
    //     roles?.includes("store manager"),
    //   elements: [
    //     {
    //       label: "Traning Documents",
    //       active: location.pathname === "/employee/academy",
    //       handleMenu: () => {
    //         dispatch(push(`/employee/academy`));
    //       },
    //       visible:
    //         roles?.includes("aggregator") ||
    //         roles?.includes("area manager") ||
    //         roles?.includes("store manager"),
    //     },
    //   ],
    // },
    {
      title: "JDs",
      visible: roles?.includes("hr"),
      icon: aggregatorIcon,
      active: location.pathname === "/employee/jds",
      elements: [
        {
          label: "All JDs",
          visible: roles?.includes("hr"),
          active: location.pathname === "/employee/jds",
          handleMenu: () => {
            dispatch(push("/employee/jds"));
          },
        },
      ],
    },
  ];

  const selectMenuOption = (menuOption) => {
    setSelectedMenu(menuOption);
    if (menuOption && menuOption?.elements?.length > 0) {
      let tabIndx = menuOption?.elements?.findIndex((a) => a.visible);
      selectTab(menuOption, tabIndx > 0 ? tabIndx : 0);
    }
  };

  const selectTab = (menuOption, tabIndx) => {
    setTab(tabIndx);
    if (menuOption && menuOption?.elements?.length > 0) {
      if (menuOption?.elements[tabIndx]?.items?.length > 0) {
        selectSubTab(menuOption, tabIndx, 0);
      } else {
        menuOption?.elements[tabIndx].handleMenu();
      }
    }
  };

  const selectSubTab = (menuOption, tabIndx, subTabIndx) => {
    setSubTab(subTabIndx);
    if (
      menuOption &&
      menuOption?.elements?.length > 0 &&
      menuOption?.elements[tabIndx]?.items?.length > 0
    ) {
      menuOption?.elements[tabIndx]?.items[subTabIndx]?.handleMenu();
    }
  };

  const getCurrentMenuOption = () => {
    return menuOptions.find((a) => a.active);
  };

  const getCurrentMenuTab = (menuOption) => {
    if (menuOption && menuOption?.elements?.length > 0) {
      return menuOption?.elements?.findIndex((a) => a.active);
    }
    return 0;
  };

  const getCurrentMenuSubTab = (menuOption, tabIndex) => {
    if (
      menuOption &&
      menuOption?.elements?.length > 0 &&
      menuOption?.elements[tabIndex]?.items?.length > 0
    ) {
      return menuOption?.elements[tabIndex]?.items?.findIndex((a) => a.active);
    }
    return 0;
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#0DAA47",
            color: "white",
          },
        }}
      >
        <Toolbar />
        <Box>
          <List>
            {menuOptions.map(
              (obj, index) =>
                obj?.visible && (
                  <ListItem
                    key={obj?.title}
                    disablePadding
                    sx={obj?.active ? { background: "#00953b" } : {}}
                  >
                    <ListItemButton
                      onClick={() => selectMenuOption(obj)}
                      sx={{ display: "block", textAlign: "center", my: "4px" }}
                    >
                      <ListItemIcon sx={{ display: "block" }}>
                        <img src={obj?.icon} alt={""} />
                      </ListItemIcon>
                      <Typography textAlign="center" sx={{ fontSize: "12px" }}>
                        {obj?.title}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </Box>
      </Drawer>
      <Box
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          mt: "64px",
          height: "46px",
        }}
      >
        <Paper elevation={1}>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={(event, newValue) =>
                  selectTab(selectedMenu, newValue)
                }
                aria-label="basic tabs example"
              >
                {selectedMenu?.elements?.map(
                  (section, index) =>
                    section?.visible && (
                      <Tab
                        sx={{ fontSize: "11px", color: "#777777" }}
                        key={index}
                        value={index}
                        label={section?.label}
                      />
                    )
                )}
              </Tabs>
            </Box>
          </Box>
        </Paper>
      </Box>
      {selectedMenu?.elements?.length > 0 &&
        selectedMenu?.elements[tab]?.items?.length > 0 &&
        selectedMenu?.elements[tab]?.visible && (
          <Box
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              mt: "4px",
              height: "46px",
            }}
          >
            <Paper elevation={0}>
              <Box sx={{ width: "100%", borderBottom: "2px solid #eeeeee" }}>
                <Box>
                  <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={subTab}
                    onChange={(event, newValue) =>
                      selectSubTab(selectedMenu, tab, newValue)
                    }
                  >
                    {selectedMenu?.elements[tab]?.items?.map((item, index) => (
                      <Tab
                        sx={{ fontSize: "11px", color: "#777777" }}
                        key={index}
                        value={index}
                        label={item?.label}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
            </Paper>
          </Box>
        )}
    </>
  );
};

export default MenuDrawer;
