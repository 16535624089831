import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { setEmployeeLoading, setMerchantLoading } from ".";
import { employee_http } from "../../https/employee_http";
import { merchant_http } from "../../https/merchant_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import { SET_EMPLOYEE_ANALYTICS_ITEM_INACCURATE_FOOD_COST } from "../actionTypes";

const PUPPETEER_API_URL = "https://api.ghostkitchensindia.com/puppeteer";

export const fetchSalesOverviewAnalytics = (referenceMonth) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/analytics/sale/overview/region?referenceMonth=${referenceMonth}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_SALES_OVERVIEW,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchTemporarySales = (fromDate, toDate) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/rista-sales/analytics`, {
        fromDate: fromDate.toISOString().split("T")[0],
        toDate: toDate.toISOString().split("T")[0],
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TEMPORARY_SALES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchTemporaryMom = (brand, branch, region) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/rista-sales/`, {
        partnerName: branch,
        brandName: brand,
        regionId: region,
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TEMPORARY_MOM,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchTemporaryBrands = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/rista-sales/brands`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_RISTA_BRANDS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadSalesExcel = (fromDate, toDate) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(
        `${API_URL}/rista-sales/export`,
        {
          fromDate: fromDate.toISOString().split("T")[0],
          toDate: toDate.toISOString().split("T")[0],
        },
        {
          responseType: "blob",
        }
      )
      .then(async (response) => {
        fileDownload(response.data, `Sales-${new Date()}.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadOverallSalesExcel = (fromDate, toDate) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(false));
    toast.success("excel file will send to your mail !");
    employee_http
      .post(
        `${API_URL}/alternate-overall-sales/export`,
        {
          fromDate: fromDate.toISOString().split("T")[0],
          toDate: toDate.toISOString().split("T")[0],
        },
        {
          responseType: "blob",
        }
      )
      .then(async (response) => {
        fileDownload(response.data, `Sales-${new Date()}.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const uploadSwiggySales = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  let url = `${API_URL}/upload-swiggy-sales/upload`;
  const data = await employee_http.post(url, formData, {
    "Content-Type": "multipart/form-data",
  });
  toast.success("Upload Successfully");
};

export const uploadADSalesData = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  let url = `${API_URL}/upload-swiggy-sales/AD/upload`;
  const data = await employee_http.post(url, formData, {
    "Content-Type": "multipart/form-data",
  });
  toast.success("Upload Successfully");
};

export const fetchSalesByRegionAnalytics = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/analytics/sale/overview/region/${id}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_SALES_BY_REGION,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchSalesByBrand = (from, to, region) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/overview/brand?&from=${from}&to=${to}`;
    if (region) {
      url = url + `&region=${region}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_SALES_BY_BRAND,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchZSDaily = async (from, to, aggregator, metric) => {
  let url = `${API_URL}/sale/zs-metrics/${aggregator}?from=${from}&to=${to}&metricType=${metric}`;
  const response = await employee_http.get(url);
  return response.data;
};
export const uploadZSDaily = async (
  file,
  aggregator,
  metric,
  referenceFromDate,
  referenceToDate
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("metricType", metric);
  formData.append("aggregator", aggregator);
  formData.append("fromDate", referenceFromDate);
  formData.append("toDate", referenceToDate);

  let url = `${API_URL}/sale/zs-metrics-bulk-upload`;
  const data = await employee_http.post(url, formData, {
    "Content-Type": "multipart/form-data",
  });
  toast.success("Upload Successfully");
};
export const fetchTimeSlotAnalysis = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/analytics/sale/timing/partner/${id}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_TIMESLOT_ANALYSIS,
          payload: res.data,
        });
        // console.log("res",res.data)

        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchItemStock = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/rista/item/stock`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_ITEM_STOCK,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchStateDetails = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/analytics/sale/overview/state?&from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_STATES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchReportCard = (from, to, partner, brands) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(
        `${API_URL}/analytics/sale/overview/partner/stats?&from=${from}&to=${to}`,
        { partner, brands }
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_REPORT_CARD,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerSalesOverviewAnalytics = (region, referenceMonth) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/overview/partner/report?referenceMonth=${referenceMonth}`;
    if (region) {
      url = url + `&region=${region}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_PARTNER_SALES_OVERVIEW,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchIRSalesOverviewAnalytics = (
  partner,
  referenceMonth,
  brand,
  source
) => {
  return (dispatch) => {
    console.log(partner, referenceMonth);
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/overview/channel/report?referenceMonth=${referenceMonth}`;
    if (partner) {
      url += `&partner=${partner}`;
    }
    if (brand) {
      url += url.includes("?") ? `&brand=${brand}` : `?brand=${brand}`;
    }
    if (source) {
      url += url.includes("?") ? `&source=${source}` : `?source=${source}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_IR_SALES_OVERVIEW,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchBrandSalesOverviewAnalytics = (partner, referenceMonth) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/overview/brand/report?referenceMonth=${referenceMonth}`;
    if (partner) {
      url += `&partner=${partner}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_BRAND_SALES_OVERVIEW,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchOutletStatus = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/rista/outlet/status`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_OUTLET_STATUS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchItemFoodCostAnalytics = (from, to, store_id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/overview/item/stats?from=${from}&to=${to}`;
    if (store_id) {
      url = url + `&store_id=${store_id}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_ITEM_FOOD_COST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchItemFoodCostInaccurateAnalytics = (
  from,
  to,
  store_id,
  tab
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/overview/item/inaccurate-stats?from=${from}&to=${to}&tab=${tab}`;
    if (store_id) {
      url = url + `&store_id=${store_id}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_ITEM_INACCURATE_FOOD_COST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadFoodCosting = (from, to, store_id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/overview/item/download?from=${from}&to=${to}`;
    if (store_id) {
      url = url + `&store_id=${store_id}`;
    }

    employee_http
      .get(url, {
        responseType: "blob",
      })
      .then(async (response) => {
        console.log("Response Content Type:", response.headers["content-type"]);
        console.log("Response Data:", response.data);
        fileDownload(response.data, "foodCosting.csv", "text/csv");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerSalesForecast = (partner_id, forecast_date) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/partner/forecast?partner_id=${partner_id}&date=${forecast_date}`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_PARTNER_SALES_FORECAST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchOOSAnalysis = (
  partner_id,
  days,
  fromDate,
  toDate,
  analyticFromDate,
  analyticToDate,
  region
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/rista/outOfStockSKUAnalyticsRegion?partner_id=${partner_id}&fromDate=${fromDate}&toDate=${toDate}&analyticFromDate=${analyticFromDate}&analyticToDate=${analyticToDate}&days_in_history=20&days_to_forecast=${days}`;
    if (region) {
      url = url + `&region_id=${region}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_OOS_ANALYSIS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchMerchantSalesForecast = (forecast_date) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    let url = `${API_URL}/analytics/sale/merchant/forecast/v2?date=${forecast_date}&days_in_history=30`;
    merchant_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_MERCHANT_SALES_FORECAST,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchEmployeePartnerSalesForecast = (forecast_date, partner) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/employee/forecast/v2?date=${forecast_date}&days_in_history=30&partner_id=${partner.id}`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_MERCHANT_SALES_FORECAST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadMerchantSalesForecast = (forecast_date) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    let url = `${API_URL}/analytics/sale/merchant/forecast/v2/download?date=${forecast_date}&days_in_history=30`;
    merchant_http
      .get(url, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `forecast_${forecast_date}.xlsx`);
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const downloadEmployeePartnerSalesForecast = (
  forecast_date,
  partner
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/employee/forecast/v2/download?date=${forecast_date}&days_in_history=30&partner_id=${partner.id}`;
    merchant_http
      .get(url, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(
          response.data,
          `forecast_${forecast_date}_${partner.label}.xlsx`
        );
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerAnalytics = (forecast_date) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/store/state/region`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_PARTNER_ANALYTICS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchTANormalisedAnalytics = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/store/dinein`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_ANALYTICS_TA_NORMALISED_SALES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerGkScores = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale_dump/gkscores`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_PARTNER_GKSCORES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadFinanceReport = (formData) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/finance-report/upload`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadTaxReport = (formData) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/tax-report/upload`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadAdjustmentReport = (formData) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/adjustment-report/upload`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchFinanceReport = (from, to, reportType) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/finance-report/all?from=${from}&to=${to}&reportType=${reportType}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_FINANCE_REPORT_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadFinanceReport = (from, to, reportType) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/finance-report/all/excel?from=${from}&to=${to}&reportType=${reportType}`,
        {
          responseType: "blob",
        }
      )
      .then(async (response) => {
        fileDownload(response.data, `finance_report_${from}_to_${to}.xls`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadPayoutForPartner = (partnerId, name) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/payout-schedules/partner/${partnerId}/excel`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `payout_schedule_report_${name}.xls`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadPayoutForMerchant = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/payout-schedules/partner/excel`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `payout_schedule_report.xls`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPayoutScheduleHealth = (query) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/payout-schedules/health`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PAYOUT_SCHEDULE_HEALTH_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAverageKPTData = (query, body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/store/averagekpt`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_OPS_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchDailyOpsDetails = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/total/dailyops`;

    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_OPS_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchDailyopsGroupByDay = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(false));
    let url = `${API_URL}/sale/dailyops/groupbyday`;

    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_OPS_EVERYDAY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchNetSaleGroupByResid = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/netSale/resId`;

    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_NETSALE_RESID,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchReviewGroupByBrand = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(false));
    let url = `${API_URL}/sale/rating/brandwise`;

    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_BRAND_RATING,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchOOSGroupByDate = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(false));
    let url = `${API_URL}/sale/oos/datewise`;

    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_OOS_DATEWISE,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchRCATData = (query, body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    console.log(body);
    let url = `${API_URL}/sale/store/getRCADetails`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_RCA_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const addRCAComments = (formData) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale/store/addRACcomment`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_TICKET_DETAILS,
        //   payload: res.data,
        // });
        await dispatch(setEmployeeLoading(false));
        toast.success("Add RCA Successfully");
      })

      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchMerchantAverageKPTData = (query, body) => {
  return (dispatch) => {
    console.log(body);

    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/store/averagekpt/partner`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    merchant_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_OPS_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchAverageKPTDetails = (query, body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/store/averagekptdetails`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_OPS_ITEM_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchVisibilityDetails = (query, body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/daily-visibility/getDetails`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_OPS_ITEM_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchMerchantAverageKPTDetails = (query, body) => {
  return (dispatch) => {
    console.log(body);
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/store/averagekptdetails`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    merchant_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_OPS_ITEM_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchDayWiseNormalizedData = (date, region) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    console.log("region", region);
    let url = `${API_URL}/analytics/sale/normalized/day-wise?last5Days=${date}`;
    if (region) {
      url += `&region=${region}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_DAY_WISE_NORMALIZED,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadLedger = (partnerId, formData) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/qsr/${partnerId}/excel`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async () => {
        toast.success("Upload in progress...");
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchLedgerForPartner = (partnerId) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/qsr/${partnerId}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_LEDGER_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("No ledger details found!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchLedgerForPartnerSummary = (partnerId) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/qsr/${partnerId}/summary`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_LEDGER_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadDailyVisibility = async (file, aggregator, expiry) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("date", expiry);
  formData.append("aggregator", aggregator);
  let url = `${API_URL}/daily-visibility/upload`;
  const data = await employee_http.post(url, formData, {
    "Content-Type": "multipart/form-data",
  });
  toast.success("Upload Successfully");
};

export const getDailyVisibilityAvg = (body1, body2, aggregator) => {
  console.log("request body->  ", body1);
  return async (dispatch) => {
    // dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/daily-visibility/averageDetails`;
    try {
      const res = await employee_http.post(url, { body1, body2, aggregator });
      // dispatch({
      //   type: actionTypes.SET_DAILY_VISIBILITY_DETAILS,
      //   payload: res.data,
      // });
      return res.data;
      // dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.error("Error fetching daily visibility data:", error);
      // dispatch(setEmployeeLoading(false));
    }
  };
};

export const fetchMissingDates = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/missing-dates`;
    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_OPS_MISSING_DATES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchMissingDatesMerchent = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/missing-dates`;
    merchant_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_OPS_MISSING_DATES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getDailyVisibilityAverage = async (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/daily-visibility/averageDetails`;
    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_DAILY_VISIBILITY_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchsalesAnalyticsReport = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/analytics/sale/salesAnalytics/partner/stats?&from=${from}&to=${to}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_SALES_ANALYTICS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const getTotalSalesAnalytics = (query, body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/store/salesAnalytics`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    employee_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TOTAL_SALE_ANALYTICS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getCrossedKTPRWT = (query) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/crossed-kpt-rwt`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    employee_http
      .post(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_CROSSED_KPT_RWT,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAverageMFRData = (query, body) => {
  return async (dispatch) => {
    // dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/store/getAverageMFR`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    try {
      const res = await employee_http.post(url, { body });

      return res.data;
      // dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.error("Error fetching daily visibility data:", error);
      // dispatch(setEmployeeLoading(false));
    }
  };
};
export const fetchMerchantAverageMFRData = (query, body) => {
  return async (dispatch) => {
    // dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/store/getAverageMFR`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    try {
      const res = await merchant_http.post(url, { body });

      return res.data;
      // dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.error("Error fetching daily visibility data:", error);
      // dispatch(setEmployeeLoading(false));
    }
  };
};

export const getTotalMFRAdoption = (query, body) => {
  return async (dispatch) => {
    // dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/store/totalEventlog`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    try {
      const res = await employee_http.post(url, { body });

      return res.data;
      // dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.error("Error fetching daily visibility data:", error);
      // dispatch(setEmployeeLoading(false));
    }
  };
};

export const poorratedReviews = (body) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale/poor/reviews`, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.POOR_RATED_ORDERS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getCOCOStores = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/analytics/sale/stores-coco`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_COCO_STORES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getAllUploadSwiggyStores = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/upload-swiggy-sales/branch`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_SWIGGY_UPLOAD_STORES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const getAllUploadSwiggyBrands = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/upload-swiggy-sales/brands`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_SWIGGY_UPLOAD_BRANDS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadUploadSwiggyBrands = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/upload-swiggy-sales/download?from=${from}&to=${to}`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `busniess-report_${new Date()}.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

function getFormattedDateTime() {
  const now = new Date();

  // Get date components
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  // Formatted date string in dd_mm_hh_mm format
  return `${day}_${month}_${hours}_${minutes}`;
}

export const downloadOOSReport = (storeId, name) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    let url = `${API_URL}/analytics/sale/downloadOOS/${storeId}`;
    employee_http
      .get(url, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(
          response.data,
          `${name.replace(" ", "")}_${getFormattedDateTime()}.xlsx`
        );
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const getOutOfStockReport = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/analytics/sale/outOfStock`, id)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_OOS_DATA,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getOutOfStockBrandWise = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/analytics/sale/outOfStockBrandWise`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_OOS_DATA,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

const handleToastLinkClick = (dispatch) => {
  dispatch(setMerchantLoading(true));
  // toast.success("Creation is in progress !");
  let url = `${API_URL}/sale/download/discount`;
  employee_http
    .get(url, {
      responseType: "blob",
    })
    .then(async (res) => {
      fileDownload(res.data, `discount-data.xlsx`);
      // createClickableToast();
      dispatch(setMerchantLoading(false));
    })
    .catch(() => {
      dispatch(setMerchantLoading(false));
    });
};

export const downloadOOSDateReport = (date) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/analytics/sale/oos-report/${date}`, {
        responseType: "blob",
      })
      .then(async (res) => {
        fileDownload(res.data, `oos_report_${date}.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

const createClickableToast = (dispatch) => {
  toast.info(
    <div>
      Created new Discount excel!{" "}
      <span
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => handleToastLinkClick(dispatch)}
      >
        Click here to Download
      </span>
    </div>,
    {
      autoClose: 15000,
    }
  );
};

export const downloadDiscountDetails = (date) => {
  return (dispatch) => {
    // dispatch(setMerchantLoading(true));
    toast.success("Creation is in progress !");
    let url = `${API_URL}/sale/create/discount`;
    employee_http
      .post(
        url,
        { date },
        {
          responseType: "blob",
        }
      )
      .then(async (response) => {
        // fileDownload(response.data, `discount_${getFormattedDateTime()}.xlsx`);
        createClickableToast(dispatch);
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const getPaymentMethods = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale/payment/method`, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.PAYMENT_METHODS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const getPaymentDetails = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale/payment/view`, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.PAYMENT_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const puppeteerAction = (data) => {
  return (dispatch) => {
    // dispatch(setAPILoading(true));
    employee_http
      .post(`${PUPPETEER_API_URL}/`, data)
      .then(async (res) => {
        // await dispatch(setAPILoading(false));
      })
      .catch(() => {
        // dispatch(setAPILoading(false));
      });
  };
};

export const fetchGoogleAccounts = (cb) => {
  return (dispatch) => {
    // dispatch(setAPILoading(true));
    employee_http
      .get(`${API_URL}/optipro/google-location/business-ids`)
      .then(async (res) => {
        cb(res.data);
      })
      .catch(() => {
        // dispatch(setAPILoading(false));
      });
  };
};
